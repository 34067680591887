import { FillableBy } from "../dynamicCompentencyForms/form_enums";

 export const evaluationCheckList = {
    title: "Method of evaluation. Check all that apply:",
    type: "fields",
    fields: [
        {
            fillable_by : FillableBy.Evaluator,
            type: "a-checkbox-group",
            span: 24,
            value: [],
            rules: {
                required: true,
                message: "This field is required.",
            },
            options: [
                "Discussion of information with employee and all questions answered.",
                "Observation followed by return demonstration.",
                "Observation of task/skill only.",
            ].map((item) => ({ text: item, span: 24 }))
        }
    ]
}