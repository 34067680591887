import { FillableBy } from "../dynamicCompentencyForms/form_enums";

export const getSignatures = (fillableBy) => {
    const allSignatures = [
        {
            fillable_by: FillableBy.Employee,
            label: "Employee Signature",
            type: "sign-pad",
            span: 12,
            sign: null,
            signed_at: null,
            rules: {
                required: true,
                message: "This field is required.",
            },
        },
        {
            fillable_by: FillableBy.Evaluator,
            label: "Evaluator Signature",
            type: "sign-pad",
            span: 12,
            sign: null,
            signed_at: null,
            rules: {
                required: true,
                message: "This field is required.",
            },
        },
    ];

    if (fillableBy === FillableBy.Both) {
        return allSignatures;
    }

    return allSignatures.filter((signature) => signature.fillable_by === fillableBy);
};
