const EnumToArray = {
    asArray(type = "label") {
      return Object.entries(this).map(([key, value]) => ({ [type]: key, value }));
    },
};

function JSEnum(inpEnum) {
    /**
     * @typedef {Object} EnumToArray
     * @property {function(string): Object[]} asArray - Converts the enum object into an array of objects.
     */

    /**
     * @type {T & EnumToArray}
     */
    const data = Object.assign(Object.create(EnumToArray), inpEnum);
    return Object.freeze(data);
}

export const FillableBy = JSEnum({
    Employee : "employee",
    Evaluator : "evaluator",
    Both : "both"
});
