import { FillableBy } from "../dynamicCompentencyForms/form_enums";

export const  employeeinfo = {
    title: "Employee Information",
    type: "fields",
    fields: [
      {
        fillable_by : FillableBy.Evaluator,
        label: "Name",
        placeholder: "Enter here...",
        type: "a-input",
        span: 12,
        value: "",
        rules: {
          required: true,
          message: "This field is required.",
        },
      },
      {
        fillable_by : FillableBy.Evaluator,
        label: "Evaluator Name",
        placeholder: "Enter here...",
        type: "a-input",
        span: 12,
        value: "",
        rules: {
          required: true,
          message: "This field is required.",
        },
      },
      {
        fillable_by : FillableBy.Evaluator,
        label: "Job Title",
        placeholder: "Enter here...",
        type: "a-input",
        span: 12,
        value: "",
        rules: {
          required: true,
          message: "This field is required.",
        },
      },
      {
        fillable_by : FillableBy.Evaluator,
        label: "Evaluator Title",
        placeholder: "Enter here...",
        type: "a-input",
        span: 12,
        value: "",
        rules: {
          required: true,
          message: "This field is required.",
        },
      },
      {
        fillable_by : FillableBy.Evaluator,
        label: "Date",
        type: "a-date-picker",
        span: 12,
        value: "",
        rules: {
          required: true,
          message: "This field is required.",
        },
      },
    ],
  }