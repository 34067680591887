import { FillableBy } from "../dynamicCompentencyForms/form_enums";
import { constants } from "../constants";

export const table_fields = [
    {
        fillable_by: FillableBy.Employee,
        type: "a-select",
        label: "",
        placeholder: "Select one...",
        rules: {
          required: true,
          message: "This field is required.",
        },
        options : constants.tableAssessmentOptions
      },
      {
        fillable_by: FillableBy.Evaluator,
        type: "a-select",
        label: "",
        placeholder: "Select one...",
        rules: {
          required: true,
          message: "This field is required.",
        },
        options: constants.tableAssessmentOptions,
      },
    {
        fillable_by : FillableBy.Evaluator,
        type: "a-date-picker",
        label: "",
        value: "",
        rules: {
            required: true,
            message: "This field is required.",
        },
    }
];
